import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../index'

const verticalsList = (state: RootState) => state.verticals.list

const verticalsSelectorConfig = (state: RootState) =>
  state.verticals.selectorConfig

const currentVerticalId = (state: RootState) =>
  state.verticals.currentVerticalId

export const verticalsListSelector = createSelector(
  [verticalsList],
  verticalsList => verticalsList,
)

export const verticalsSelectorConfigSelector = createSelector(
  [verticalsSelectorConfig],
  verticalsSelectorConfig => verticalsSelectorConfig,
)

export const currentVerticalIdSelector = createSelector(
  [currentVerticalId],
  currentVerticalId => currentVerticalId,
)

export const currentVerticalSelector = createSelector(
  [verticalsList, currentVerticalId],
  (verticalsList, currentVerticalId) =>
    verticalsList.find(vertical => vertical.id === currentVerticalId),
)

export const defaultVerticalSelector = createSelector(
  [verticalsList],
  verticalsList => verticalsList[0],
)

export const verticalsLoadingSelector = createSelector(
  [verticalsList, (state: RootState) => state.verticals.loading],
  (verticalsList, loading) => !verticalsList?.length && loading,
)
