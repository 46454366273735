import { useAuth0 } from '@auth0/auth0-react'
import { useAllTaxonomyItemsForNetworkLazyQuery } from '@generated/graphql'
import { defaultLanguageIdSelector } from '@store/slices/languages/selectors'
import { currentNetworkSelector } from '@store/slices/networks/selectors'
import {
  setAllTaxonomyItems,
  setAllTaxonomyItemsError,
} from '@store/slices/taxonomies'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { useCallback, useEffect } from 'react'

export function useFetchAllTaxonomyItems(refetchOnly: boolean = false) {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const templatesLoading = useAppSelector(state => state.templates.loading)
  const currentNetwork = useAppSelector(currentNetworkSelector)
  const defaultLanguageId = useAppSelector(defaultLanguageIdSelector)
  const currentNetworkId = currentNetwork?.id

  const [query, { refetch, called }] = useAllTaxonomyItemsForNetworkLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ taxonomyItems }) => {
      dispatch(setAllTaxonomyItems(taxonomyItems))
    },
    onError: error => {
      console.error('Unable to load taxonomies', error)
      dispatch(setAllTaxonomyItemsError(error.message))
    },
  })

  useEffect(() => {
    if (
      !isAuthenticated ||
      !defaultLanguageId ||
      !currentNetworkId ||
      templatesLoading ||
      refetchOnly
    ) {
      return
    }

    query({
      variables: getQueryVariables(defaultLanguageId, currentNetworkId),
    })
  }, [
    isAuthenticated,
    query,
    defaultLanguageId,
    dispatch,
    currentNetworkId,
    templatesLoading,
    refetchOnly,
  ])

  const _refetch = useCallback(() => {
    if (called) {
      refetch(getQueryVariables(defaultLanguageId, currentNetworkId))
    } else {
      query({
        variables: getQueryVariables(defaultLanguageId, currentNetworkId),
      })
    }
  }, [refetch, defaultLanguageId, currentNetworkId, called])

  if (refetchOnly) {
    return {
      refetch: _refetch,
    }
  }
}

const getQueryVariables = (defaultLanguageId, currentNetworkId) => {
  return {
    languageId: defaultLanguageId,
    where: {
      networkId: { equals: currentNetworkId },
    },
  }
}
