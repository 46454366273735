import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Template, TemplatesState } from './types'

const initialState: TemplatesState = {
  list: [],
  loading: true,
}

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setTemplates(state, action: PayloadAction<Template[]>) {
      state.list = action.payload
      state.loading = false
    },
    setTemplatesLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

export default templatesSlice.reducer

export const { setTemplates, setTemplatesLoading } = templatesSlice.actions
