import { StringItemTranslationType } from '@generated/graphql'
import { Language } from '@store/slices/languages/types'
import { Network } from '@store/slices/networks/types'
import { Site } from '@store/slices/sites/types'
import { Template, TemplateField } from '@store/slices/templates/types'
import { Vertical } from '@store/slices/verticals/types'
import { FormApi, FormState } from 'final-form'

export enum DynamicFormModes {
  Add = 'add',
  Edit = 'edit',
  View = 'view',
}

export interface IRequiredValidationReturn {
  (value: string): string
}

export interface IGetFieldByFormKey {
  formKey?: string
  templateFields?: TemplateField[]
}

export interface IGetPropertyValueOfName {
  name: string
  values: FormState<any>
  template: Template
}

export interface IFilterResourceContentByLanguage {
  resource: any
  currentLanguageTag: Language['languageTag']
}

export interface IAddContentVariantAffix {
  key: string
  variant: number
}

export interface IGetFieldValueFromForm {
  field: TemplateField
  dirtyFields: any
  values: any
  overrideDirtyCheck: boolean
}

export interface IGetFieldValueFromFormReturn {
  key: string
  data: string
  variant: number
}

export interface IPrepareContent {
  values: any
  template: Template
  currentLanguage: Language
  dirtyFields: any
  data: any
}

export interface IPrepareContentReturn {
  language: {
    languageTag: Language['languageTag']
  }
  items: {
    key: string
    data: any
    variant: number
    translation: StringItemTranslationType.Human
    shouldBeApproved: boolean
  }[]
}

export interface IPrepareProperties {
  values: any
  template: Template
  dirtyFields: any
  currentNetwork: Network
  currentVertical: Vertical
}

export interface IPreparePropertiesReturn {
  items: { key: string; data: any }[]
}

export interface IValuesToGraphqlData {
  values: any
  form: FormApi
  template: Template
  currentNetwork: Network
  currentVertical: Vertical
  currentSite: Site
  currentLanguage: Language
  data: any
  connectionResourcesData?: TConnectionResourceData
}

export interface IValuesToGraphqlDataReturn {
  (): Promise<{
    current: {
      network: Network['id']
      vertical: Vertical['id']
      site: Site['id']
    }
    properties: IPreparePropertiesReturn
    content: IPrepareContentReturn
  }>
}

type TConnectionResourceData = {
  resources: any[]
  template: Template
}[]

export interface IGraphqlDataToInitialValues {
  data: any
  template: Template
  currentLanguageTag: Language['languageTag']
  currentNetwork: Network
  currentVertical: Vertical
  connectionResourcesData?: TConnectionResourceData
}

export interface IGraphqlDataToInitialValuesReturn {
  [key: string]: any
}
