import { setLeftMenuData, setTopMenuData } from '@store/slices/menu'
import * as data from '@store/slices/menu/helpers'
import {
  menuLeftDataSelector,
  menuTopDataSelector,
} from '@store/slices/menu/selectors'
import { settingsSelector } from '@store/slices/settings/selectors'
import { templatesSelector } from '@store/slices/templates/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { isEqual } from 'lodash'
import { useEffect } from 'react'

export function useCreateMenu() {
  const dispatch = useAppDispatch()
  const templates = useAppSelector(templatesSelector)
  const { language } = useAppSelector(settingsSelector)
  const prevMenuLeftData = useAppSelector(menuLeftDataSelector)
  const prevMenuTopData = useAppSelector(menuTopDataSelector)

  useEffect(() => {
    const menuLeftData = data.getLeftMenuData(templates, language)
    const menuTopData = data.getTopMenuData(templates, language)
    if (!isEqual(prevMenuLeftData, menuLeftData)) {
      dispatch(setLeftMenuData(menuLeftData))
    }
    if (!isEqual(prevMenuTopData, menuTopData)) {
      dispatch(setTopMenuData(menuTopData))
    }
  }, [dispatch, language, templates, prevMenuLeftData, prevMenuTopData])
}
