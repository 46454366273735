import { useConnectLiveUsers } from '@store/hooks/useConnectLiveUsers'
import { useCreateMenu } from '@store/hooks/useCreateMenu'
import { useFetchAllTaxonomyItems } from '@store/hooks/useFetchAllTaxonomyItems'
import { useFetchCounts } from '@store/hooks/useFetchCounts'
import { useFetchLanguages } from '@store/hooks/useFetchLanguages'
import { useFetchNetworks } from '@store/hooks/useFetchNetworks'
import { useFetchSites } from '@store/hooks/useFetchSites'
import { useFetchTemplates } from '@store/hooks/useFetchTemplates'
import { useFetchVariables } from '@store/hooks/useFetchVariables'
import { useFetchVerticals } from '@store/hooks/useFetchVerticals'
import React, { FC, ReactNode } from 'react'

const InitialStoreComponent: FC<{ children: ReactNode }> = ({ children }) => {
  useFetchLanguages()
  useFetchNetworks()
  useFetchVerticals()
  useFetchSites()
  useFetchCounts()
  useFetchTemplates()
  useCreateMenu()
  useConnectLiveUsers()
  useFetchAllTaxonomyItems()
  useFetchVariables()

  return <>{children}</>
}

export default InitialStoreComponent
