import React from 'react'

import { useTopSelector } from './hooks/useTopSelector'

const TopSelectorSet = ({ topSelector, children }) => {
  useTopSelector(topSelector)
  return <>{children}</>
}

export default TopSelectorSet
