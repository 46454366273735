import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Language, LanguagesState } from './types'

const initialState: LanguagesState = {
  list: [],
  loading: true,
}

const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    setLanguages(state, action: PayloadAction<Language[]>) {
      state.list = action.payload
      state.loading = false
    },
    setLanguagesLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

export default languagesSlice.reducer

export const { setLanguages, setLanguagesLoading } = languagesSlice.actions
