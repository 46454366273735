import { useAuth0 } from '@auth0/auth0-react'
import { currentNetworkSelector } from '@store/slices/networks/selectors'
import {
  resetVerticals,
  setVerticals,
  setVerticalsLoading,
} from '@store/slices/verticals'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { useEffect } from 'react'

export function useFetchVerticals() {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const networkLoading = useAppSelector(state => state.networks.loading)
  const verticalLoading = useAppSelector(state => state.verticals.loading)
  const currentNetwork = useAppSelector(currentNetworkSelector)

  useEffect(() => {
    if (networkLoading && !verticalLoading) {
      dispatch(setVerticalsLoading(true))
    }
  }, [dispatch, networkLoading, verticalLoading])

  useEffect(() => {
    if (!isAuthenticated || networkLoading) {
      return
    }

    if (!currentNetwork) {
      dispatch(setVerticalsLoading(false))
      dispatch(resetVerticals())
      return
    } else {
      dispatch(setVerticals(currentNetwork.verticals))
    }
  }, [networkLoading, isAuthenticated, currentNetwork, dispatch])
}
