import {
  BellOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { removeNotification } from '@store/slices/notifications'
import { notificationsSelector } from '@store/slices/notifications/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { Avatar, Badge, List, Popover } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

import styles from './style.module.scss'

const NotificationsMenu = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const notifications = useAppSelector(notificationsSelector)

  return (
    <Popover
      overlayClassName={styles.notificationOverlayWrapper}
      title="Notifications"
      placement="bottomRight"
      content={
        <div className={styles.popoverContent}>
          <List
            dataSource={notifications}
            renderItem={item => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={e => {
                  e.cancelBubble = true
                  e.stopPropagation()
                  e.preventDefault()

                  if (item.url) {
                    history.push({
                      pathname: item.url,
                      search: history.location.search,
                    })
                  }
                }}>
                <List.Item
                  actions={[
                    <CloseOutlined
                      onClick={e => {
                        e.cancelBubble = true
                        e.stopPropagation()
                        e.preventDefault()
                        dispatch(removeNotification(item?.id))
                      }}
                      style={{ fontSize: '14px' }}
                    />,
                  ]}>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        icon={
                          item?.type === 'info' && (
                            <InfoCircleOutlined style={{ color: '#1890ff' }} />
                          )
                        }
                        style={{ backgroundColor: '#fff' }}
                      />
                    }
                    title={item?.message}
                  />
                </List.Item>
              </div>
            )}
          />
        </div>
      }>
      <div className={styles.hoverable}>
        <Badge count={notifications?.length ?? 0} size="small" offset={[-6, 6]}>
          <Avatar icon={<BellOutlined />} className={styles.menuIcon} />
        </Badge>
      </div>
    </Popover>
  )
}

export default NotificationsMenu
