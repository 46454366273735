import { ExclamationCircleOutlined, RocketOutlined } from '@ant-design/icons'
import CountryFlagIcon from '@components/CountryFlagIcon'
import { LinkWithQuery } from '@components/LinkWithQuery'
import { useCheckPermissions } from '@components/Permissions/useCheckPermissions'
import { DEFAULT_LANGUAGE } from '@constants'
import { NAME_KEY } from '@constants/fieldKeys'
import { getPropertyValue } from '@modules/property'
import {
  currentLanguageSelector,
  currentSiteIdSelector,
  currentSiteSelector,
  sitesListSelector,
  sitesSelectorConfigSelector,
} from '@store/slices/sites/selectors'
import { useAppSelector } from '@store/typedHooks'
import { permissions } from '@wdnsolutions/auth-helpers'
import { Dropdown, Input } from 'antd'
import classNames from 'classnames'
import React, { memo, ReactNode, useMemo, useState } from 'react'

import useTopSelectorChange from '../hooks/useChangeTopSelector'
import styles from '../style.module.scss'
import { filterSitesBySearch } from './methods'

const SiteSelect = () => {
  const selectorConfig = useAppSelector(sitesSelectorConfigSelector)
  const currentSite = useAppSelector(currentSiteSelector)
  const language = useAppSelector(currentLanguageSelector)

  const menu = useRenderMenu()

  return (
    <Dropdown
      disabled={selectorConfig.disabled}
      menu={menu}
      trigger={['click']}
      placement="bottomLeft">
      <div
        className={classNames(
          styles.dropdown,
          selectorConfig.disabled && styles.disabled,
        )}>
        {renderSite(currentSite)}
        {renderLanguage(language)}
      </div>
    </Dropdown>
  )
}

export default memo(SiteSelect)

function renderSite(currentSite) {
  if (!currentSite) {
    return <span className="text-uppercase text-white">All sites</span>
  }

  return (
    <span className="badge badge-pill badge-light d-inline small">
      {getPropertyValue(NAME_KEY, currentSite?.properties)}
    </span>
  )
}

function renderLanguage(language) {
  return (
    <CountryFlagIcon
      countryCode={language.countryCode}
      style={{ marginLeft: '5px' }}
    />
  )
}

export interface IMenuItem {
  key: string
  label?: ReactNode | string
  disabled?: boolean
  type?: string
  icon?: ReactNode
}

export type HandleSelectClickType = ({ key }: { key: string }) => void

function useRenderMenu() {
  const sites = useAppSelector(sitesListSelector)
  const currentSiteId = useAppSelector(currentSiteIdSelector)
  const isSiteCreateAllowed = useCheckPermissions([permissions.site.create])
  const { onTopSelectorChange } = useTopSelectorChange('site')

  const [search, setSearch] = useState('')

  const menuItems = useMemo(() => {
    const handleSearch = (value: string): void => {
      setSearch(value)
    }

    const handleSearchChange = ({
      target: { value },
    }: {
      target: HTMLInputElement
    }): void => {
      setSearch(value)
    }

    const menuItems: IMenuItem[] = [
      {
        key: 'all',
        label: 'All sites',
      },
      { key: 'divider1', type: 'divider' },
      {
        key: 'search',
        disabled: true,
        label: (
          <Input.Search
            allowClear
            value={search}
            placeholder="Type to filter"
            onSearch={handleSearch}
            onChange={handleSearchChange}
          />
        ),
      },
    ]

    const sitesToRender = search
      ? filterSitesBySearch(sites, search)
      : [...sites]

    sitesToRender
      ?.sort((a, b) => {
        const aSiteUrl = getPropertyValue(NAME_KEY, a?.properties) || ''
        const bSiteUrl = getPropertyValue(NAME_KEY, b?.properties) || ''

        return aSiteUrl.localeCompare(bSiteUrl)
      })
      .reduce((enFirstSortedSites, site) => {
        if (site.language.languageTag === DEFAULT_LANGUAGE.languageTag) {
          enFirstSortedSites.unshift(site)
        } else {
          enFirstSortedSites.push(site)
        }

        return enFirstSortedSites
      }, [])
      .forEach(site => {
        menuItems.push({
          key: site.id,
          label: (
            <>
              <CountryFlagIcon
                countryCode={site.language?.countryCode}
                style={{ marginRight: '10px' }}
              />
              {getPropertyValue(NAME_KEY, site?.properties)}
            </>
          ),
        })
      })

    if (sitesToRender?.length) {
      menuItems.push({ key: 'divider2', type: 'divider' })
    }

    if (!sitesToRender?.length) {
      menuItems.push({
        key: 'noContent',
        disabled: true,
        icon: <ExclamationCircleOutlined />,
        label: <span style={{ fontWeight: 500 }}>No data available</span>,
      })
    }

    if (isSiteCreateAllowed) {
      menuItems.push({
        key: 'add',
        label: (
          <LinkWithQuery to="/sites/add">
            <RocketOutlined className="mr-2" /> Add site
          </LinkWithQuery>
        ),
      })
    }

    return menuItems
  }, [isSiteCreateAllowed, sites, search, setSearch])

  const handleSelectClick: HandleSelectClickType = ({ key }) => {
    setSearch('')
    onTopSelectorChange({ key })
  }

  return {
    selectable: false,
    selectedKeys: [currentSiteId],
    onClick: handleSelectClick,
    items: menuItems,
  }
}
