import {
  DEFAULT_SELECTOR_SEARCH_PARAM_KEY,
  USER_SELECTED_NETWORK_KEY,
  USER_SELECTED_VERTICAL_KEY,
} from '@constants'
import StorageHelper from '@modules/storage'
import { ISearchParams } from '@pages/router/components/TopSelectorSet/hooks/useTopSelector'
import { setIsUnsavedChangesModal } from '@store/slices/editor'
import { currentNetworkIdSelector } from '@store/slices/networks/selectors'
import { currentSiteIdSelector } from '@store/slices/sites/selectors'
import { currentVerticalIdSelector } from '@store/slices/verticals/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import qs from 'query-string'
import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const useTopSelectorChange = (type: 'network' | 'vertical' | 'site') => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isDirtyForm = useAppSelector(state => state.editor.isDirty)
  const currentNetworkId = useAppSelector(currentNetworkIdSelector)
  const currentVerticalId = useAppSelector(currentVerticalIdSelector)
  const currentSiteId = useAppSelector(currentSiteIdSelector)

  const onTopSelectorChange = useCallback(
    ({ key }) => {
      if (key === 'add') {
        return
      }
      if (type === 'network' && key === currentNetworkId) return
      if (type === 'vertical' && key === currentVerticalId) return
      if (type === 'site' && key === currentSiteId) return

      const searchParamsObj: ISearchParams = qs.parse(location.search)
      const newSearchParamsObj: ISearchParams = {
        ...searchParamsObj,
      }
      if (type === 'network') {
        newSearchParamsObj.network = key
        newSearchParamsObj.vertical = DEFAULT_SELECTOR_SEARCH_PARAM_KEY
        newSearchParamsObj.site = DEFAULT_SELECTOR_SEARCH_PARAM_KEY
      } else if (type === 'vertical') {
        newSearchParamsObj.vertical = key
        newSearchParamsObj.site = DEFAULT_SELECTOR_SEARCH_PARAM_KEY
      } else if (type === 'site') {
        newSearchParamsObj.site = key
      }

      const topSelectorChangeCallback = () => {
        if (type === 'network') {
          StorageHelper.setItem(
            USER_SELECTED_NETWORK_KEY,
            newSearchParamsObj.network,
          )
        }
        if (type === 'vertical') {
          StorageHelper.setItem(
            USER_SELECTED_VERTICAL_KEY,
            newSearchParamsObj.vertical,
          )
        }
        history.push({
          search: qs.stringify(newSearchParamsObj),
        })
      }

      if (isDirtyForm) {
        dispatch(
          setIsUnsavedChangesModal({
            isUnsavedChangesModal: true,
            unsavedChangesDiscardAction: topSelectorChangeCallback,
          }),
        )
      } else {
        topSelectorChangeCallback()
      }
    },
    [
      currentNetworkId,
      currentVerticalId,
      currentSiteId,
      isDirtyForm,
      location.search,
      dispatch,
      history,
      type,
    ],
  )

  return { onTopSelectorChange }
}

export default useTopSelectorChange
