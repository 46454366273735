import { TemplateType } from '@generated/graphql'
import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../index'

const templatesList = (state: RootState) => state.templates.list

export const templatesSelector = createSelector(
  [templatesList],
  templatesList => templatesList,
)

export const templateByTypeSelector = createSelector(
  [
    templatesList,
    (templatesList, type: TemplateType) => type,
    (templatesList, type: TemplateType, subtype?: string) => subtype,
  ],
  (templatesList, type, subtype) =>
    subtype
      ? templatesList.find(
          template => template.type === type && template.subtype === subtype,
        )
      : templatesList.find(template => template.type === type),
)

export const templatesByTypeSelector = createSelector(
  [templatesList, (templatesList, type: TemplateType) => type],
  (templatesList, type) =>
    templatesList.filter(template => template.type === type),
)

export const templatesLoadingSelector = createSelector(
  [templatesList, (state: RootState) => state.templates.loading],
  (templatesList, loading) => !templatesList?.length && loading,
)
