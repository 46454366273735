import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AllTaxonomyItemForNetwork, AllTaxonomyItemsState } from './types'

const initialState: AllTaxonomyItemsState = {
  list: [],
  loading: true,
  error: undefined,
}

const allTaxonomyItemsSlice = createSlice({
  name: 'allTaxonomyItems',
  initialState,
  reducers: {
    setAllTaxonomyItems(
      state,
      action: PayloadAction<AllTaxonomyItemForNetwork[]>,
    ) {
      state.list = action.payload
      state.loading = false
    },
    setAllTaxonomyItemsError(state, action: PayloadAction<string>) {
      state.error = action.payload
      state.loading = false
    },
  },
})

export default allTaxonomyItemsSlice.reducer

export const { setAllTaxonomyItems, setAllTaxonomyItemsError } =
  allTaxonomyItemsSlice.actions
