import { useAuth0 } from '@auth0/auth0-react'
import { useLanguagesLazyQuery } from '@generated/graphql'
import { setLanguages } from '@store/slices/languages'
import { useAppDispatch } from '@store/typedHooks'
import { useEffect } from 'react'

export function useFetchLanguages() {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()

  const [queryLanguages] = useLanguagesLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ languages }) => {
      dispatch(setLanguages(languages))
    },
    onError: error => {
      console.error('Unable to load languages', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    queryLanguages()
  }, [isAuthenticated, queryLanguages])
}
