import { useAuth0 } from '@auth0/auth0-react'
import { fb, useFirebaseAuthStatus } from '@modules/firebase'
import { pushNotification } from '@store/slices/notifications'
import { useAppDispatch } from '@store/typedHooks'
import { useEffect, useState } from 'react'

const Notifications = ({ children }) => {
  const { db, ref, onChildAdded, orderByChild, startAt, query, onValue } = fb
  const dispatch = useAppDispatch()
  const isFirebaseAuthenticated = useFirebaseAuthStatus()

  const { user } = useAuth0()
  const [timeOffset, setTimeOffset] = useState(null)

  const notificationsRef = ref(db, 'notifications/all')

  useEffect(() => {
    if (!user || !isFirebaseAuthenticated) return
    const unsubscribe = onValue(ref(db, '.info/serverTimeOffset'), snap => {
      const offset = snap.val()
      setTimeOffset(offset)
    })
    return () => unsubscribe()
  }, [user, isFirebaseAuthenticated])

  useEffect(() => {
    if (timeOffset === null || !isFirebaseAuthenticated || !user) return

    const queryRef = query(
      notificationsRef,
      orderByChild('createdAt'),
      startAt(timeOffset + Date.now()),
    )

    const unsubscribe = onChildAdded(queryRef, snap => {
      try {
        const data = snap.val()
        let url
        switch (data?.event?.resourceType) {
          case 'taxonomyItem':
            url = `/taxonomies/edit/${data?.event?.id}`
            break
          case 'network':
            url = `/${data?.event?.resourceType}s/edit`
            break
          default:
            url = `/${data?.event?.resourceType}s/${
              data?.event?.templateSubType
                ? `${data?.event?.templateSubType}/`
                : ''
            }edit/${data?.event?.id}`
            break
        }

        // Prevent sending notifications to same user
        if (user?.sub !== data?.event?.user) {
          if (data?.message) {
            dispatch(
              pushNotification({
                id: data?.event?.id,
                message: data?.message,
                type: 'info',
                url,
              }),
            )
          }
        }
      } catch (error) {
        //
      }
    })

    return () => unsubscribe()
  }, [user, timeOffset, isFirebaseAuthenticated])

  return children
}

export default Notifications
