import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../index'

const notifications = (state: RootState) => state.notifications.list

export const notificationsSelector = createSelector(
  [notifications],
  notifications => notifications,
)
